import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router"
import { Layout, Table, Tooltip } from "antd";
import { Header, ContentEx as Content, Footer, FunctionTitle, CommonMessage, FooterButtonArea } from "../../core/CorePageContents"
import styles from "./WorkerAttendanceDetail.module.css";
import * as Module from "../../../modules/prime/workerAttendance/workerAttendanceModule";
import * as Model from "../../../modules/prime/workerAttendance/workerAttendanceModel";
import { initMessages } from "../../../modules/core/commonMessageModule";
import * as formatUtils from "../../../utils/formatUtils";
import * as CONSTANTS from "../../../constants/constants";
import moment, { Moment } from "moment";
import { AuthButton } from "../../core/CoreForm";
import { LeftSquareFilled, RightSquareFilled } from "@ant-design/icons";
import AuthButtonStyles from "../../core/css/AuthButton.module.css";
import { initApiResult } from "../../../modules/core/bffApiModule";

import { AbolishCompanyListButton } from '../../core/parts/AbolishCompanyListButton'
import { MergeCompanyAnnotations } from '../../core/parts/MergeCompanyAnnotations'

interface titleInterface {
  title: string;
}

export const WorkerAttendanceDetail = (props: titleInterface) => {

  const dispatch = useDispatch();
  let dataList = useSelector(Module.storedDetailDataList);
  let criteria = useSelector(Module.storedDetailCriteria);
  let total = useSelector(Module.storedDetailTotal);
  let belongCompanyId = useSelector(Module.storedCompanyId);
  let belongCompanyName = useSelector(Module.storedCompanyName);
  let workerId = useSelector(Module.storedWorkerId);
  let name = useSelector(Module.storedName);
  let primeCertificationNo = useSelector(Module.storedPrimeCertificationNo);
  let managementGroupName = useSelector(Module.storedManagementGroupName);
  let qualificationListTotal = useSelector(Module.storedQualificationListTotal);
  let history = useHistory();

  useEffect(() => {
    callGetDetailDataListApi();
  },
    [criteria]
  );

  /**
   * 一次協力会社名の一覧の整形
   * @param text 
   * @param row 
   */
  const renderfirstPartnerCompanyName = (text: string, row: Model.WorkRecordWorkerDetailRow) => {
    if (row.displayDivision === "0") {
      let firstPartnerCompanyNames: Array<any> = [];
      let promptTextList: Array<any> = [];

      let index = 0;
      row.firstPartnerCompanyList.forEach((partnerRow: Model.FirstPartnerCompanyRow) => {
        if (row.firstPartnerCompanyList.length >= 4 && index === 2) {
          // 一次協力会社が4つ以上ある場合、3件目以降は「...」で表示する
          firstPartnerCompanyNames.push(<div key={row.belongCompanyId + index} className={styles.leftText}>...</div>);
        } else if (row.firstPartnerCompanyList.length < 4 || index < 3) {
          firstPartnerCompanyNames.push(
            <div key={row.belongCompanyId + index} className={styles.leftText}>{partnerRow.firstPartnerCompanyName}</div>
          );
        }

        // Tooltipに表示するメッセージの作成
        promptTextList.push(<div>{partnerRow.firstPartnerCompanyName}</div>);
        index++;
      });

      if (row.firstPartnerCompanyList.length >= 4) {
        return {
          children: (<Tooltip placement="topLeft" title={promptTextList}><div className={styles.leftText}>{firstPartnerCompanyNames}</div></Tooltip>)
        }
      } else {
        return {
          children: firstPartnerCompanyNames
        }
      }
    } else {
      return (
        {
          props: {
            colSpan: 0,
          },
        }
      )
    }
  };

  /**
   * 月末の日付の取得
   * @param attendanceYearMonth 
   */
  const getLastDay = (attendanceYearMonth: string) => {
    // 年
    let year = parseInt(attendanceYearMonth.substring(0, 4));

    // 月
    let month = parseInt(attendanceYearMonth.substring(4, 6));

    return new Date(year, month, 0).getDate();
  }

  /**
   * 日毎の勤務実績の作成
   * @param attendanceYearMonth 
   */
  const getDaily = (attendanceYearMonth: string) => {
    let daysColumn = [];

    // 年
    let year = parseInt(attendanceYearMonth.substring(0, 4));

    // 月
    let month = parseInt(attendanceYearMonth.substring(4, 6));

    // 月の最終日の取得
    let lastDay = getLastDay(attendanceYearMonth);

    // 曜日文字列定義
    const weekDayChars = ["日", "月", "火", "水", "木", "金", "土"]

    for (let i = 1; i <= lastDay; i++) {
      // 日付オブジェクトの作成
      let day = new Date(year, month - 1, i);

      daysColumn.push(
        {
          title: i,
          dataIndex: "day" + i,
          key: "day" + i,
          className: styles.day,
          align: "center" as "center",
          children: [{
            title: weekDayChars[day.getDay()],
            className: styles.day,
            align: "center" as "center",
            render: (test: string, row: Model.WorkRecordWorkerDetailRow) => {
              // プロジェクトのデータを表示
              if (row.displayDivision === "0") {
                // プロジェクト明細を表示
                if (isNotSubmitted(row)) {
                  if (i === 1) {
                    return (
                      {
                        props: {
                          colSpan: getLastDay(criteria.attendanceYearMonth)
                        },
                        children: (<div>未提出</div>)
                      }
                    )
                  } else {
                    return (
                      {
                        props: {
                          colSpan: 0
                        },
                      }
                    )
                  }
                } else if (isStatusForceApprovedFromNotSubmitted(row)) {
                  if (i === 1) {
                    return (
                      {
                        props: {
                          colSpan: getLastDay(criteria.attendanceYearMonth)
                        },
                        children: (<div>未提出の勤怠を条件付き承認しました。</div>)
                      }
                    )
                  } else {
                    return (
                      {
                        props: {
                          colSpan: 0
                        },
                      }
                    )
                  }
                } else {

                  // 労働時間の取得
                  let workingTime = formatUtils.formatTime(row.dailyWorkStatusList[i - 1].workingTime);
                  let isValid = isValidPeriod(row.dailyWorkStatusList[i - 1].meetQualificationRequirementType);

                  return (
                    <>
                      <div className={styles.working}>{isValid ?
                        workingTime === "" || workingTime === "0:00" ? <>&nbsp;</> : workingTime : "－"}</div>
                    </>
                  )
                }
                // 労働時間の合算行を表示
              } else if (row.displayDivision === "1") {
                // 労働時間合算行を表示
                let momentSummary: Moment = moment({ hours: 0, minutes: 0 });
                let isValid: boolean = false;
                let existSubmitted = false;
                dataList.forEach(row => {
                  if (row.displayDivision === "0") {
                    // 提出済、確認済、承認済、承認済＊（提出済、確認済から承認）が合算対象
                    if (!isNotSubmitted(row) && !isStatusForceApprovedFromNotSubmitted(row)) {

                      // 労働時間の取得
                      isValid = isValidPeriod(row.dailyWorkStatusList[i - 1].meetQualificationRequirementType)
                      if (isValid) {
                        let currentMoment: Moment = moment(row.dailyWorkStatusList[i - 1].workingTime, ["HH:mm", moment.ISO_8601])
                        momentSummary.add("h", currentMoment.hour()).add("m", currentMoment.minute())
                      }
                      existSubmitted = true;
                    }
                  }
                });

                if (existSubmitted === false) {
                  // 未提出の場合、日別の労働時間の合計は0とする
                  isValid = true;
                  momentSummary = moment({ hours: 0, minutes: 0 });
                }

                return (
                  <>
                    <div className={styles.working}>{isValid ?
                      momentSummary.hour() === 0 && momentSummary.minute() === 0 ? <>&nbsp;</> : momentSummary.format("H:mm") : "－"}</div>
                  </>
                )
                // 資格のデータを表示
              } else if (row.displayDivision === "2") {
                // 資格行を表示
                let isValid: boolean = false;
                isValid = isValidPeriod(row.dailyQualificationRequirementList[i - 1].matchFlag)

                return (
                  <>
                    <div className={styles.working}>{isValid ?
                      row.dailyQualificationRequirementList[i - 1].matchFlag === "0" ? <>&nbsp;</> : "〇" : "－"}</div>
                  </>
                )
              }
            },
          }]
        }
      );
    }
    return daysColumn;
  }

  /** 有効期間内かどうか */
  const isValidPeriod = (type: string) => {
    return type !== CONSTANTS.QUALIFICATION_REQUIREMENT_TYPE.OUTSIDE_VALID_PERIOD.code;
  }

  /** 未提出かどうかチェック */
  const isNotSubmitted = (row: Model.WorkRecordWorkerDetailRow) => {
    return isStatusNotSubmitted(row) || isStatusRemand(row);
  }

  /** 承認済＊（未提出、差戻から承認）かどうかチェック */
  const isStatusForceApprovedFromNotSubmitted = (row: Model.WorkRecordWorkerDetailRow): boolean => {
    return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_ON && (row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_NOT_SUBMITTED || row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_REMAND);
  }

  /** 承認済＊かどうかチェック */
  const isStatusForceApproved = (row: Model.WorkRecordWorkerDetailRow): boolean => {
    return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_ON;
  }

  /** 承認済かどうかチェック */
  const isStatusApproved = (row: Model.WorkRecordWorkerDetailRow): boolean => {
    return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_APPROVED;
  }

  /** 確認済かどうかチェック */
  const isStatusConfirmed = (row: Model.WorkRecordWorkerDetailRow): boolean => {
    return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_CONFIRMED;
  }

  /** 差戻かどうかチェック */
  const isStatusRemand = (row: Model.WorkRecordWorkerDetailRow): boolean => {
    return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_REMAND;
  }

  /** 未提出かどうかチェック */
  const isStatusNotSubmitted = (row: Model.WorkRecordWorkerDetailRow): boolean => {
    return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_NOT_SUBMITTED;
  }

  /**
   * CriteriaをStoreに保持
   * @param keyValueList 
   */
  const criteriaDataTransfer = (keyValueList: Array<{ key: string, value: any }>) => {
    let newCriteria: Model.WorkRecordWorkerDetailCriteria = {
      processType: criteria.processType,
      attendanceYearMonth: criteria.attendanceYearMonth,
      projectId: criteria.projectId,
      workerId: workerId,
      belongCompanyId: belongCompanyId,
      belongCompanyName: criteria.belongCompanyName,
      belongCompanyNameKana: criteria.belongCompanyNameKana,
      primeCertificationNo: criteria.primeCertificationNo,
      lastName: criteria.lastName,
      firstName: criteria.firstName,
      lastNameKana: criteria.lastNameKana,
      firstNameKana: criteria.firstNameKana,
      firstPartnerCompanyName: criteria.firstPartnerCompanyName,
      firstPartnerCompanyNameKana: criteria.firstPartnerCompanyNameKana,
      status: criteria.status,
      offset: criteria.offset,
      limit: criteria.limit,
    }
    keyValueList.forEach((rec: { key: string, value: any }) => {
      newCriteria[rec.key] = rec.value;
    })
    dispatch(Module.setDetailCriteria(newCriteria));
  }

  /** 外部urlからの遷移かチェック*/
  if (window.location.search !== "" && criteria.attendanceYearMonth === "" && belongCompanyId === "" && workerId === 0) {
    let urlParamStr = window.location.search.substring(1);

    let attendance_year_month = "";
    let belong_company_id = "";
    let worker_id = "";
    urlParamStr.split("&").forEach(param => {
      const temp = param.split("=");

      if (temp[0] === "attendanceYearMonth") {
        attendance_year_month = temp[1];
      } else if (temp[0] === "belongCompanyId") {
        belong_company_id = temp[1];
      } else if (temp[0] === "workerId") {
        worker_id = temp[1];
      }
    });

    // URLパラメータが全て入力されていない場合は上書きしない
    if (attendance_year_month !== "" &&
      belong_company_id !== "" &&
      worker_id !== "") {
      let urlParamValues: Model.UrlParameter = {
        belongCompanyId: belong_company_id
        , workerId: worker_id
        , attendanceYearMonth: attendance_year_month
      }
      dispatch(Module.setUrlParameterValue(urlParamValues));
    } else {
      history.push(CONSTANTS.PATH_PRIME_WORKERS_ATTENDANCE_SUMMARY);
    }
  } else if (window.location.search === ""
    && belongCompanyId === ""
    && workerId === 0
    && criteria.attendanceYearMonth === "") {
    history.push(CONSTANTS.PATH_PRIME_WORKERS_ATTENDANCE_SUMMARY);
  }

  /** 一覧部 */
  let columns = [
    {
      title: "表示項目",
      align: "center" as "center",
      render: (text: string, row: Model.WorkRecordWorkerDetailRow, index: number) => {
        if (index === 0) {
          // データの行数分で表示
          return (
            {
              props: {
                rowSpan: total,
                align: "center",
                className: styles.displayItem,
              },
              children: (
                <>
                  <div>プロジェクト別</div>
                  <div>勤務時間</div>
                </>
              )
            }
          )
        } else if (index < total) {
          // データの表示をしない
          return (
            {
              props: {
                rowSpan: 0,
                className: styles.displayItem,

              },
            }
          )
          // 労働時間の合算行を表示
        } else if (row.displayDivision === "1") {
          // 4列分で表示
          return (
            {
              props: {
                colSpan: 5,
                className: styles.displayItem,

              },
              children: (
                <>
                  <div className={styles.leftText}>全プロジェクト合算</div>
                </>
              )
            }
          )
          // 資格のデータを表示
        } else if (row.displayDivision === "2") {
          if (index === total + 1) {
            // 資格データの行数分で表示
            return (
              {
                props: {
                  rowSpan: qualificationListTotal,
                },
                children: (
                  <>
                    <div>資格別該当日数</div>
                  </>
                )
              }
            )
          } else {
            // 表示しない
            return (
              {
                props: {
                  rowSpan: 0,
                },
              }
            )
          }
        }
      },
    },
    {
      title: (
        <>
          <div>プロジェクト名/</div>
          <div>独自資格名</div>
        </>
      ),
      className: styles.projectName,
      align: "center" as "center",
      render: (text: string, row: Model.WorkRecordWorkerDetailRow) => {
        // プロジェクトのデータを表示
        if (row.displayDivision === "0") {
          return (
            <>
              <span className={styles.leftText}>{row.projectName}</span>
              <AbolishCompanyListButton historyId={row.workRecordSubmissionHistoryId} abolishCompanyFlag={row.companyMergedAttendanceFlag} />
            </>
          )
          // 労働時間の合算行を表示
        } else if (row.displayDivision === "1") {
          return (
            {
              props: {
                colSpan: 0,
              },
            }
          )
          // 資格のデータを表示
        } else if (row.displayDivision === "2") {
          return (
            {
              props: {
                colSpan: 4,
              },
              children: (
                <>
                  <div className={styles.leftText}>{row.originalQualificationName}</div>
                </>
              )
            }
          )
        }
      }
    },
    {
      title: "状態",
      className: styles.status,
      align: "center" as "center",
      render: (text: string, row: Model.WorkRecordWorkerDetailRow) => {
        // プロジェクトのデータを表示
        if (row.displayDivision === "0") {
          let label = "";
          if (isStatusForceApproved(row)) {
            label = "承認済＊";
          } else {
            switch (row.approvalStatus) {
              case CONSTANTS.ATTENDANCE_STATUS_APPROVED:
                label = "承認済"
                break;
              case CONSTANTS.ATTENDANCE_STATUS_CONFIRMED:
                label = "確認済"
                break;
              case CONSTANTS.ATTENDANCE_STATUS_UNAPPROVED:
                label = "未承認"
                break;
              case CONSTANTS.ATTENDANCE_STATUS_REMAND:
                label = "差戻"
                break;
              case CONSTANTS.ATTENDANCE_STATUS_NOT_SUBMITTED:
                label = "未提出"
                break;
            }
          }
          // 労働時間の合算行を表示
          if (isStatusConfirmed(row)) {
            // 確認済みの場合、確認日、確認者を表示
            return (
              <>
                <span>{label}</span><br />
                <span>({formatUtils.formatDateSlash(row.confirmDate)})</span><br />
                <span>{row.confirmName}</span>
              </>)
            // 資格のデータを表示
          } else if (isStatusApproved(row) || isStatusForceApproved(row)) {
          // 承認済、承認済＊の場合、承認日、承認者を表示
          return (
              <>
                <span>{label}</span><br />
                <span>({formatUtils.formatDateSlash(row.approvalDate)})</span><br />
                <span>{row.approvalName}</span>
              </>)
          } else {
            return (
              <>
                <span>{label}</span>
              </>)
          }
        } else {
          return (
            {
              props: {
                colSpan: 0,
              },
            }
          )
        }
      }

    },
    {
      title: "一次協力会社",
      className: styles.firstPartner,
      align: "center" as "center",
      render: renderfirstPartnerCompanyName
    },
    {
      title: "作業次数",
      className: styles.topHierarchyLevel,
      align: "center" as "center",
      render: (text: string, row: Model.WorkRecordWorkerDetailRow) => {
        // プロジェクトのデータを表示
        if (row.displayDivision === "0") {
          return (
            <>
              <div className={styles.contentRight}>
                <span>{row.topHierarchyLevel}</span>
              </div>
            </>
          )
        } else {
          return (
            {
              props: {
                colSpan: 0,
              },
            }
          )
        }
      }
    },
    {
      title: (
        <>
          <div>合計時間/</div>
          <div>資格要件該当日数</div>
        </>
      ),
      className: styles.item,
      align: "center" as "center",
      render: (text: string, row: Model.WorkRecordWorkerDetailRow) => {
        // プロジェクトのデータを表示
        if (row.displayDivision === "0") {
          return (
            <>
              <div className={styles.totalWorkingValue}>{row.totalWorkingTime}</div>
            </>
          )
          // 労働時間の合算行を表示
        } else if (row.displayDivision === "1") {
          return (
            <>
              <div className={styles.totalWorkingValue}>{row.summaryTotalWorkingTime}</div>
            </>
          )
          // 資格のデータを表示
        } else if (row.displayDivision === "2") {

          // 資格要件該当日数を求める
          let lastDay = getLastDay(criteria.attendanceYearMonth);
          let qualificationCount: number = 0;
          for (let i = 1; i <= lastDay; i++) {
            let isValid: boolean = false;
            isValid = isValidPeriod(row.dailyQualificationRequirementList[i - 1].matchFlag)
            qualificationCount += isValid ? row.dailyQualificationRequirementList[i - 1].matchFlag === "1" ? 1 : 0 : 0
          }

          return (
            <>
              <div className={styles.totalWorkingValue}>{qualificationCount + "日"}</div>
            </>
          )
        }
      }
    },
    {
      title: "労働者時間実績／資格要件該当状態（〇…資格要件に該当、印なし…資格要件に該当しない、－…資格有効期間外）",
      className: styles.workingRecordTitle,
      align: "center" as "center",
      children: getDaily(criteria.attendanceYearMonth)
    }
  ];

  const callGetDetailDataListApi = () => {

    // パラメータがない場合はデータの取得を行わない
    if (belongCompanyId === ""
      || workerId === 0
      || criteria.attendanceYearMonth === "") {
      return;
    }

    let params: Model.getDetailDataList.Request = {
      processType: CONSTANTS.DETAIL_PROCESS_TYPE_PAYMENT,
      attendanceYearMonth: criteria.attendanceYearMonth,
      projectId: criteria.projectId,
      workerId: workerId,
      belongCompanyId: belongCompanyId,
      belongCompanyName: criteria.belongCompanyName,
      belongCompanyNameKana: criteria.belongCompanyNameKana,
      primeCertificationNo: criteria.primeCertificationNo,
      lastName: criteria.workerLastName,
      firstName: criteria.workerFirstName,
      lastNameKana: criteria.workerLastNameKana,
      firstNameKana: criteria.workerFirstNameKana,
      firstPartnerCompanyName: criteria.firstPartnerCompanyName,
      firstPartnerCompanyNameKana: criteria.firstPartnerCompanyNameKana,
      status: criteria.status,
      offset: criteria.offset,
      limit: criteria.limit
    }
    dispatch(Module.getDetailDataListAsync(params));
  }

  /**
   * 戻るボタン押下
   */
  const onClickBack = () => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    history.push(CONSTANTS.PATH_PRIME_WORKERS_ATTENDANCE_SUMMARY);
  }


  /**
   * 勤怠年月変更日イベント
   * @param addValue 
   */
  const onChangeMonth = (addValue: number) => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    // API処理結果を初期化します。
    dispatch(initApiResult());

    let year = parseInt(criteria.attendanceYearMonth.substring(0, 4));
    let month = parseInt(criteria.attendanceYearMonth.substring(4, 6));

    // 変更後の日付インスタンスの作成
    let newDate = new Date(year, (month - 1) + addValue, 1);

    year = newDate.getFullYear();
    month = newDate.getMonth() + 1;

    criteriaDataTransfer([
      { key: "attendanceYearMonth", value: year.toString() + ("0" + month).slice(-2) }
    ]);
  }

  /** 一覧ヘッダー部 */
  const AttendanceDetailHeader = () => {
    return (
      <div style={{ whiteSpace: "nowrap" }} className={styles.searchGroup}>
        <div className={styles.flexContainer}>
          <div className={styles.headerTitle}>勤怠年月：</div>
          <div>
            <label style={{ color: "#006a5d", margin: "4px" }} onClick={(e) => onChangeMonth(-1)}><LeftSquareFilled /></label>
            {criteria.attendanceYearMonth.substring(0, 4)}年{parseInt(criteria.attendanceYearMonth.substring(4, 6))}月
              <label style={{ color: "#006a5d", margin: "4px" }} onClick={(e) => onChangeMonth(1)}><RightSquareFilled /></label>
          </div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.headerTitle} style={{ marginLeft: "20px" }}>所属会社：</div>
          <div>{belongCompanyName}</div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.headerTitle} style={{ marginLeft: "20px" }}>管理グループ：</div>
          <div>{managementGroupName}</div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.headerTitle} style={{ marginLeft: "20px" }}>元請認定番号：</div>
          <div>{primeCertificationNo}</div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.headerTitle} style={{ marginLeft: "20px" }}>氏名：</div>
          <div>{name}</div>
        </div>
      </div>
    )
  }

  /** 一覧部 */
  const AttendanceContent = () => {
    if (total > 0) {
      return (
        <>
          <Table
            size="small"
            className="hoverNotBackground"
            columns={columns}
            dataSource={dataList}
            pagination={false}
            rowClassName={(record, index) => {
              if (index === total) {
                return styles.rowRed
              } else if (index > total) {
                return styles.rowBlue
              }
              return "";
            }
            }
          >
          </Table>
        </>
      );
    } else {
      return <></>
    }
  }

  /** ボタン部 */
  const AttendanceButton = () => {
    return (
      <FooterButtonArea>
        <AuthButton
          name={"戻る"}
          size={"large"}
          shape={"round"}
          onClick={onClickBack}
          style={{ margin: "10px" }}
          className={AuthButtonStyles.backButtonFixed}
        />
      </FooterButtonArea>
    )
  }

  return (
    <Layout>
      <Header />
      <Content>
        <FunctionTitle title={props.title} />
        <CommonMessage />
        <AttendanceDetailHeader />
        <div style={{ float: "left" }}>作業次数は複数編成されている場合、もっとも上位の次数を表示します。</div>
        <AttendanceContent />
        <MergeCompanyAnnotations
          attendanceList={dataList}
          mergeCompanyAttendanceFlgName={"companyMergedAttendanceFlag"}
        />
      </Content>
      <AttendanceButton />
      <Footer />
    </Layout>
  );
}


